<template>
    <div class="healthKnow xi">
        <hd-com></hd-com>
        <div class="content-wrap">
            <div class="content clearfix">
                <el-breadcrumb separator-class="el-icon-arrow-right">
                    <el-breadcrumb-item :to="{ path: '/video/videoPage' }">当前位置：视频</el-breadcrumb-item>
                    <el-breadcrumb-item>{{ videoDetail.name }}</el-breadcrumb-item>
                </el-breadcrumb>
                <!-- 内容 -->
                <div class="videodetail">
                    <!-- 标题 -->
                    <div class="flex-space-between">
                        <div class="flex-space-between">
                            <div class="videoName">{{ videoDetail.name }}</div>

                            <div style="display:flex;margin: 0 20px;" v-if="videoDetail.isCharge == true">
                                <div style="margin-right: 10px">市场价： {{ videoDetail.userPrice }}元</div>
                                <div style="margin-right: 10px;">会员价：
                                    <span style="color:#FF0000;"> {{ videoDetail.vipPrice }}元</span>
                                </div>
                                <el-button type="success" round size="mini" @click="gobuyVideo"
                                    v-if="dialogFormVisible">购买</el-button>
                            </div>
                            <div style="display:flex" v-else>
                                <div
                                    style="width: 40px;height: 22px;line-height: 22px;background: #01C2AC;border-radius: 4px;text-align: center;margin: 0 20px;">
                                    免费</div>
                            </div>
                            <div v-if="videoDetail.videoCategory">分类：{{ videoDetail.videoCategory.name }}</div>
                        </div>
                        <div class="flex-space-between collection-div">
                            <i v-if="iscollect == false" class="el-icon-star-off"
                                style="color:#01C2AC;cursor: pointer;font-weight: bold;" @click="gocollect"> 收藏</i>
                            <i v-else class="el-icon-star-on" style="color:#01C2AC;cursor: pointer;font-weight: bold;"
                                @click="gocollect"> 取消收藏</i>
                        </div>
                    </div>

                    <div style="display:flex; margin-top: 20px;">
                        <div style="width:calc(100% - 300px)">
                            <div class='videoPlaydetail flex-center'>
                                <video id="media"  class="video-js vjs-big-play-centered" :src="videoDetail.url" style='object-fit: contain;' >
                                       
                                    </video>
                                <!-- <el-tag v-if="showSkip" class="" style="position: absolute;right: 10px;top: 10px;z-index: 55;cursor: pointer;" effect="dark"
                                    @click="skip(11)">跳过片头</el-tag> -->
                            </div>
                            <!-- 视频评论 -->
                            <div style="color:#999999; background-color: #26262B; padding: 15px">
                                <i class="el-icon-view" style="margin-left: 30px"> 播放量：{{
                                    videoDetail.visitCount
                                }}</i>
                                <i class="el-icon-chat-dot-square" style="margin-left: 60px"> 评论数：{{
                                    videoDetail.commentCount
                                }}</i>
                                <i class="el-icon-star-off" style="margin-left: 60px"> 总收藏：{{
                                    videoDetail.likeCount
                                }}</i>
                            </div>
                        </div>
                        <!-- 视频信息 -->
                        <div class="videoright">
                            <div style="display:flex;padding:22px 22px 0;">
                                <div>
                                    <div class="heardimg">
                                        <img src="../../assets/img/video/avatar.png" alt="">
                                    </div>
                                </div>
                                <div style="margin-left: 10px">
                                    <div v-if="videoDetail.videoType == 1">
                                        {{ videoDetail.userName }}
                                    </div>
                                    <div v-if="videoDetail.videoType == 2">
                                        {{ videoDetail.OrganizationName }}
                                    </div>
                                    <div v-if="videoDetail.videoType == 3">
                                        千越医助手
                                    </div>
                                    <div style="display:flex; color:#999999">
                                        <div>上传时间：{{ videoDetail.addTime }}</div>
                                        <!-- <div>12：20：14</div> -->
                                    </div>
                                </div>
                            </div>
                            <div class="bottom-div">
                                <div class="right-div">
                                    <el-tabs stretch v-model="activeName" @tab-click="handleClick">
                                        <el-tab-pane :label="labelname" name="first">
                                            <el-scrollbar style="height:450px">
                                                <!-- <div> -->
                                                <!-- videocheck -->
                                                <div class="videono" v-for="(item, index) in HotVideoList" :key="index"
                                                    @click="gotail(item)">
                                                    <i class="el-icon-video-play"
                                                        style="font-size:20px;margin: 5px 10px 0 0;color: #999999;"></i>
                                                    <div style="width: 210px;">
                                                        <div class="oneLines">{{ item.name }}</div>
                                                        <div class="flex-space-between" style="color:#999999">
                                                            <div style="">时长：{{ item.durationResult }}
                                                            </div>
                                                            <div style="color:#F99F6D" v-if="item.isCharge == false">免费
                                                            </div>
                                                            <div style="color:#F99F6D" v-if="item.isCharge == true">收费
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                                <!-- </div> -->
                                            </el-scrollbar>
                                        </el-tab-pane>
                                        <el-tab-pane label="简介" name="second">
                                            <el-scrollbar style="height:450px">
                                                <p class="introduce-div">{{ videoDetail.introduce }}</p>
                                            </el-scrollbar>
                                        </el-tab-pane>
                                        <el-tab-pane label="评论" name="third">
                                            <el-scrollbar style="height:315px;padding-bottom: 5px;">
                                                <div style="padding:0 20px 10px" v-for="(item, index) in CommentList"
                                                    :key="index">
                                                    <div style="">
                                                        <div style="display:flex">
                                                            <div style="color: #00998B;">{{ item.userName }}</div>
                                                            <div style="display:flex; color:#999999;margin-left: 10px;">
                                                                <div>{{ item.addTime | timefilters }}</div>
                                                                <!-- <div>12：20：14</div> -->
                                                            </div>
                                                        </div>
                                                        <div style="color: #CCCCCC;">
                                                            {{ item.contents }}
                                                        </div>
                                                    </div>
                                                    <div v-if="item.reply" style="background-color: #424242;padding: 2px 10px;">
                                                        <div style="display:flex;">
                                                            <div style="color: rgb(248, 83, 83);">作者回复</div>
                                                            <div style="display:flex; color:#999999;margin-left: 10px;">
                                                                <div>{{ item.replyTime | timefilters }}</div>
                                                                <!-- <div>12：20：14</div> -->
                                                            </div>
                                                        </div>
                                                        <div style="color: #999999;">
                                                            {{ item.reply }}
                                                        </div>
                                                    </div>
                                                </div>
                                            </el-scrollbar>
                                            <div>
                                                <el-input type="textarea" :autosize="{ minRows: 4, maxRows: 4 }"
                                                    placeholder="请输入您对视频的评价内容" v-model="contentValue" resize="none">
                                                </el-input>
                                                <div class="flexlr" style="padding-right:0;">
                                                    <div></div>
                                                    <el-button type="primary" size="mini" @click="commentSub">发表</el-button>
                                                </div>
                                            </div>
                                        </el-tab-pane>
                                    </el-tabs>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="center-box">
                <div class="content-div clearfix" style="position: relative;">
                    <div class="tuiJian">相关推荐</div>
                    <div class="center-center flex-start flex-wrap">
                        <div class="video-item flex-space-between-columns" v-for="(item, index) in HotVideoList"
                            :key="index" @click="gotail(item)">
                            <div class="item-img">
                                <img src="../../assets/img/contact-us-img.jpg" v-if="!item.cover && !item.aliyunCoverUrl"
                                    style="width:100%; height:100%" />
                                <img v-else :src="item.cover ? item.cover : item.aliyunCoverUrl" alt="" srcset=""
                                    style="width:100%; height:100%">
                                <div class="mark" v-if="item.isCharge">付费</div>
                            </div>

                            <div class="desc-box  flex-space-between-columns">
                                <div class="desc-top">
                                    <div class="title1 oneLines">
                                        <!-- <el-tooltip class="item" effect="dark" :content="item.name" placement="top-start"> -->
                                            <div>{{ item.name }}</div>
                                        <!-- </el-tooltip> -->
                                    </div>
                                    <div class="title2 twoLines">
                                        <!-- <el-tooltip class="item" effect="dark" :content="item.introduce"
                                            placement="top-start"> -->
                                            <div>{{ item.introduce }}</div>
                                        <!-- </el-tooltip> -->
                                    </div>
                                </div>
                                <div class="desc-bottom flex-space-between">
                                    <template v-if="item.isCharge == false">
                                        <div class="bottom-left">{{ item.visitCount }}人已观看</div>
                                        <div class="bottom-right1">免费</div>
                                    </template>
                                    <template v-else>
                                        <div class="bottom-left">{{ item.totalSale }}人已购买</div>
                                        <div class="bottom-right2">
                                            <span style="color:#E41313;background: none;">￥{{ item.userPrice
                                            }}</span>
                                            <!-- 会员价：<span>￥{{ item.viP2Price }}</span> -->
                                            <!-- <p class="price">￥{{ item.userPrice }}</p> -->
                                        </div>
                                    </template>
                                </div>
                            </div>
                        </div>
                        <!-- <div class="video-item flex-space-between-columns" v-for="(item, index) in HotVideoList"
                            :key="index" @click="gotail(item)">
                            <div class="item-img">
                                <img src="../../assets/img/contact-us-img.jpg" v-if="!item.cover && !item.aliyunCoverUrl"
                                    style="width:100%; height:100%" />
                                <img v-else :src="item.cover ? item.cover : item.aliyunCoverUrl" alt="" srcset=""
                                    style="width:100%; height:100%">
                            </div>

                            <div class="desc-box  flex-space-between-columns">
                                <div class="desc-top">
                                    <div class="title1 oneLines">{{ item.name }}</div>
                                    <div class="title2 twoLines">{{ item.introduce }}</div>
                                </div>
                                <div class="desc-bottom flex-space-between">
                                    <template v-if="item.isCharge == false">
                                        <div class="bottom-left">{{ item.visitCount }}人已观看</div>
                                        <div class="bottom-right1">免费</div>
                                    </template>
                                    <template v-else>
                                        <div class="bottom-left">{{ item.totalSale }}人已购买</div>
                                        <div class="bottom-right2">￥{{ item.userPrice }}</div>
                                    </template>
                                </div>
                            </div>
                        </div> -->
                    </div>
                </div>
                <el-dialog title="请前往小程序购买" :show-close="false" :close-on-click-modal="false"
                    :visible.sync="dialogFormVisible" width="30%">
                    <div class="flex-center">
                        <img src="../../assets/img/ampCode.jpg" alt="">
                    </div>
                </el-dialog>

            </div>
        </div>
        <ft-com></ft-com>
        <rt-bar></rt-bar>
    </div>
</template>
<script>
import { videoMain } from "../../components/video/video"
import hdCom from '../../components/header'
import ftCom from '../../components/footer'
import rtBar from "../../components/right";
export default {
    name: "healthKnow",
    components: {
        hdCom,
        ftCom,
        rtBar
    },
    data() {
        var video = new videoMain(this.TokenClient, this.Services.Statics)
        var newUserDistributor = new videoMain(this.TokenClient, this.Services.Authorization)
        return {
            videoDomain: video,
            newUserDistributorDomain: newUserDistributor,
            userInfo: this.$store.state.localData.userInfo,
            defaultActive: 0,
            analysisRank: [],
            activeName: 'first',
            textarea: '',
            labelname: '目录',
            videoDetail: {
                videoCategory: {
                    name: ""
                }
            },
            collectVideoList: [],
            pageIndexcollect: 1,
            HotVideoList: [],
            CommentList: [],
            keyWordComment: ' ',
            pageIndexComment: 1,
            contentValue: '',
            iscollect: false,
            dialogFormVisible: false,
            radio: '1',
            http: this.Services.Statics,
            wxpayimg: '',
            timer: null,
            msg: '',
            userBalance: {},
            showSkip:true
        }
    },
    created() {

        clearInterval(this.timer)
        this.Detail()
    },
    watch: {
        msg(curVal) {
            if (/[^\d]/g.test(curVal)) {
                this.msg = this.msg.replace(/[^\d]/g, '');
            }
        },
    },
    mounted(){
        var player = this.$video(media,{
             controls: true,           
            autoplay: false,
        });
    },
    methods: {
        skip(value) {
            this.showSkip=false
            var video = document.getElementById("media");
            video.currentTime += value;
            video.play()
        },
        focus() {
            this.$refs.pwd.focus();
        },
        gopayVideo() {
            if (this.radio == '2') {
                this.gowxpay()
            } else {
                this.gomoneypay()
            }
        },
        getisUserDistributor() {
            this.newUserDistributorDomain.isUserDistributor((data) => {
                this.userBalance = data.data
            },
                function (error) {

                })
        },
        caclePayVideo() {
            this.$router.go(-1)
        },
        // 微信支付
        gowxpay() {
            var _this = this
            let params = {
                payWay: 1,
                videoId: _this.videoDetail.id,
            }
            _this.videoDomain.getVideoPayNative(params, (data) => {
                _this.wxpayimg = _this.http + data.data.qRcodeUrl
                let ispay = {
                    orderNo: data.data.orderNo,
                    attach: 'payVideo'
                }
                _this.timer = window.setInterval(() => {
                    setTimeout(function () {
                        _this.videoDomain.getVideoIsPaySuccess(ispay, (data) => {
                            if (data.data.isSuccess == true) {
                                clearInterval(_this.timer)
                                _this.$message({
                                    type: 'success',
                                    message: '购买成功!'
                                });
                                _this.Detail()
                                _this.dialogFormVisible = false
                            }
                        },
                            function (error) {
                                clearInterval(_this.timer)
                            })
                    }, 0)
                }, 5000)
            },
                function (error) {

                })
        },
        // 零钱支付
        gomoneypay() {
            let params = {
                payWay: 1,
                videoId: this.videoDetail.id,
            }
            this.videoDomain.getPayByBalance(params, (data) => {

            },
                function (error) {

                })
        },
        // 是否收藏操作
        gocollect() {
            if (this.iscollect == false) {
                let params = {
                    EntityId: this.videoDetail.id,
                    EntityName: this.videoDetail.name,
                    userId: this.$store.state.localData.userInfo.id,
                    operate: 2
                }
                this.videoDomain.operateVideoCollection(params, (data) => {
                    this.iscollect = data.data.isOk
                },
                    function (error) {

                    })
            } else {
                let params = {
                    EntityId: this.videoDetail.id,
                    EntityName: this.videoDetail.name,
                    userId: this.$store.state.localData.userInfo.id,
                    operate: 3
                }
                this.videoDomain.operateVideoCollection(params, (data) => {
                    this.iscollect = data.data.isOk
                },
                    function (error) {

                    })
            }
        },
        getIsChargeVideo() {
            let _this = this
            if (_this.videoDetail.isCharge == true) {
                let params = _this.$store.state.localData.userInfo.id + '/' + _this.$route.query.videoId
                _this.videoDomain.getIsPayVideo(params, (data) => {

                },
                    function (error) {
                        if (_this.videoDetail.userId != _this.userInfo.id) {
                            _this.dialogFormVisible = true
                        }

                        // _this.$alert('暂未购买视频, 是否购买?', '提示', {
                        //     confirmButtonText: '确定',
                        //     cancelButtonText: '取消',
                        //     showClose: false,
                        //     type: 'warning'
                        //     }).then(() => {
                        //         _this.dialogFormVisible = true

                        //     }).catch(() => {

                        // });
                    })
            }
        },
        getoperateVideoCollection() {
            let params = {
                EntityId: this.videoDetail.id,
                userId: this.$store.state.localData.userInfo.id,
                operate: 1
            }
            this.videoDomain.operateVideoCollection(params, (data) => {
                this.iscollect = data.data.isOk
            },
                function (error) {

                })
        },
        // 跳转详情
        gotail(e) {
            this.$router.push({
                path: './videoDetail',
                query: {
                    videoId: e.id
                }
            })
            this.$router.go(0)
        },
        // 推荐合集名称
        handleClick(tab, event) {
            this.labelname = '推荐'
        },
        //详情获取
        Detail() {
            var _this = this;
            _this.videoDetail = {}
            let params = _this.$route.query.videoId + '/' + 'true'
            _this.videoDomain.VideoDetail(params, function (data) {
                _this.videoDetail = (data.data)
                _this.videoDetail.addTime = _this.videoDetail.addTime.slice(0, 10)
                _this.getCommentList(_this.videoDetail.id)
                _this.getoperateVideoCollection()
                _this.getIsChargeVideo()
                _this.getisUserDistributor()
                if (_this.videoDetail.collectionId != null) {
                    _this.GetCollectionVideoList(_this.videoDetail.collectionId)
                } else {
                    _this.hotList()
                }

                _this.labelname = _this.videoDetail.collectionId != null ? '目录（共' + _this.HotVideoList.length + '集）' : '推荐'
            },
                function (error) {

                })
        },
        // 获取合集视频
        GetCollectionVideoList(collectionId) {
            var _this = this;
            let params = collectionId + '/-999/%20/' + this.pageIndexcollect
            _this.videoDomain.GetCollectionVideo(params, function (data) {
                _this.HotVideoList = (data.data.results)
                for (let i = 0; i < _this.HotVideoList.length; i++) {
                    _this.HotVideoList[i].addTime = _this.collectVideoList[i].addTime.slice(0, 10)
                }
            },
                function (error) {

                })
        },
        // 获取推荐视频
        hotList() {
            var _this = this;
            let params = '2/%20/1'
            _this.videoDomain.GetHotList(params, function (data) {
                _this.HotVideoList = (data.data.results)
                for (let i = 0; i < _this.HotVideoList.length; i++) {
                    _this.HotVideoList[i].addTime = _this.HotVideoList[i].addTime.slice(0, 10)
                }
            },
                function (error) {

                })
        },
        // 获取评论
        getCommentList(videoId) {
            var _this = this;
            let params = videoId + '/' + _this.keyWordComment + '/' + _this.pageIndexcollect
            _this.videoDomain.GetVideoAllComment(params, function (data) {
                _this.CommentList = (data.data.results)
                for (let i = 0; i < _this.CommentList.length; i++) {
                    // _this.CommentList[i].addTime = _this.CommentList[i].addTime.slice(0, 10)
                }
            },
                function (error) {

                })
        },
        // 提交评论
        commentSub() {
            if (this.contentValue == '') {
                this.$message({
                    message: '请填写评价内容',
                    type: 'warning'
                });
            } else {
                let params = {
                    videoId: this.videoDetail.id,
                    userId: this.$store.state.localData.userInfo.id,
                    contents: this.contentValue,
                    userName: this.$store.state.localData.userInfo.name
                }
                this.videoDomain.AddComment(params, (data) => {
                    this.$message({
                        message: '评价成功',
                        type: 'success'
                    });
                    this.contentValue = ''
                    this.getCommentList(this.videoDetail.id)
                },
                    function (error) {

                    })
            }
        },
        // 购买视频弹窗
        gobuyVideo() {
            this.dialogFormVisible = true
        }

    },
    destroyed() {
        //销毁监听
        // window.removeEventListener("scroll", this.handleScroll);
        clearInterval(this.timer)
    },
}
</script>

<style lang="scss" scoped>
::v-deep .vjs-text-track-display{
	bottom: 0 !important;
    background: url("../../assets/img/logo.png") no-repeat;
    background-size:175px 50px !important
}
 ::v-deep .videoPlaydetail video {
    max-width:100% !important
 }
 ::v-deep .media-dimensions {
    width: 100%;
    height: 100%;
 }
.video-js .vjs-time-control{display:block;}
.video-js .vjs-remaining-time{display: none;}
.paybox1 {
    position: relative;
    margin-top: 60px;
    width: 80%;
    margin-left: 10%;
    height: 436px;
}

.paybox {
    position: relative;
    margin-top: 30px;
    width: 80%;
    margin-left: 10%;
    height: 436px;
    /* background: #01C2AC; */
    background: url("../../assets/img/wx_pay.jpg")top center no-repeat;
    background-size: cover;
}

.paybox2 {
    position: relative;
    width: 100%;
    height: 300px;
    /* margin-top:30px; */
    /* background: #01C2AC; */
}

.paybox2-1 {
    width: 300px;
    height: 300px;
}

.paybox2 img {
    width: 100%;
    height: 100%;
}

.paybox img {
    position: absolute;
    width: 55%;
    top: 140px;
    left: 100px;
}

.videoright {
    /* position: absolute;
    top: 30px;
    right: 30px; */
    width: 300px;
    background: #333;

}

.videocheck {
    display: flex;
    padding: 15px 30px;
    background-color: #1E1E1E;
}

.videono {
    display: flex;
    padding: 15px 20px;
    cursor: pointer;
    border-bottom: 1px dashed #333333;
}

.videono:hover {
    background-color: #242424;
}

.videodetail {
    padding: 30px 0;
    /* width: 100%; */
    background-color: #191919;
    color: #fff;

    .collection-div {
        cursor: pointer;
    }
}

.videoName {
    font-size: 24px;
    font-weight: bold;
}


.flexlr {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
}

.flextb {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px;
}

.videoBox {
    width: 115%;
    display: flex;
    flex-wrap: wrap;
}

.videoPlay {
    width: 23%;
    box-shadow: 0px 4px 6px rgba(75, 75, 75, 0.2);
    border-radius: 4px;
    background-color: #fff;
    margin: 17px 1%;
}

.videoPlaybox {
    width: 100%;
    height: 196px;

    video {
        width: 100%;
        height: 100%;
        object-fit: fill
    }
}


.videoPlaydetail {
    width: calc(1200px - 300px);
    height: 562px;
    background-color: #000;
    position: relative;

    video {
        max-width: calc(1200px - 300px);
        height: 100%;
        object-fit: fill;
    }
}


.heardimg {
    width: 56px;
    height: 56px;

    img {
        width: 100%;
        height: 100%;
        border-radius: 50%;
    }
}


.videoContent {
    padding: 15px;
}

.banner {
    height: 116px;
    background: url("../../assets/img/jbk_banner.png")center no-repeat;
}

.banner-text {
    width: 1200px;
    margin: 0 auto;

    h3 {
        padding-top: 25px;
        font-size: 26px;
        font-weight: normal;
        color: #ffffff;
    }

    p {
        font-size: 18px;
        color: #ffffff;
    }

    a {
        color: #ffffff;
        font-size: 14px;
        margin: 0 5px;
    }
}

.content-wrap {
    background: #F5F6F6;
}

.content-top {
    height: 64px;
    background: #FFFFFF;
    box-shadow: 2px 0 5px rgba(102, 102, 102, 0.08);
}

.content {
    position: relative;
    width: 1200px;
    background: #191919;
    margin: 0 auto;
    padding: 30px calc((100vw - 1200px)/2);
    min-height: 500px;
}

.content ::v-deep .el-breadcrumb__inner {
    color: #fff;
}

.content ::v-deep .el-breadcrumb__inner:hover {
    color: #fff;
}

.content ::v-deep .is-link:hover {
    color: #01C2AC;
    cursor: pointer;
}

.ny_container {
    float: right;
    width: 1030px;
    background: #FFFFFF;
    box-shadow: 0px 5px 5px rgba(224, 224, 224, .3);
    padding: 10px 0;
}

.readAllBtn {
    position: relative;
    height: 78px;
    margin-top: -68px;
    background-image: linear-gradient(rgba(255, 255, 255, 0.5), #ffffff);
    text-align: center;
}

.xi .readAllBtn .btn-text {
    position: absolute;
    bottom: 0;
    color: #1fb7a6;
    cursor: pointer;
}

/* 密码支付 */
.payPwd {
    height: auto;
}

.payPwd input[type=tel] {
    width: 0.1px;
    height: 0.1px;
    color: transparent;
    position: relative;
    top: 23px;
    background: #000000;
    left: 46px;
    border: none;
    font-size: 18px;
    opacity: 0;
    z-index: -1;
}

.psd-blink {
    display: inline-block;
}

.pwd-wrap {
    width: 90%;
    height: 50px;
    padding-bottom: 1px;
    margin: 0 auto;
    background: #fff;
    border: 1px solid #ddd;
    display: flex;
    display: -webkit-box;
    display: -webkit-flex;
    cursor: pointer;
    /* position: absolute;
        left: 0;
        right: 0;
        top: 13%; */
    z-index: 10;
}

.pwd-wrap li {
    list-style-type: none;
    text-align: center;
    line-height: 50px;
    -webkit-box-flex: 1;
    flex: 1;
    -webkit-flex: 1;
    border-right: 1px solid #ddd;
}

.pwd-wrap li:last-child {
    border-right: 0;
}

.pwd-wrap li i {
    height: 10px;
    width: 10px;
    border-radius: 50%;
    background: #000;
    display: inline-block;
}

.center-box {
    .content-div {
        position: relative;
        width: 1200px;
        margin: 0 auto;
        padding: 30px 0;
    }

    .center-center {
        .video-item {
            width: 23.5%;
            height: 330px;
            margin-right: 2%;
            margin-bottom: 30px;
            background: #ffffff;
            border-radius: 0px 0px 4px 4px;
            cursor: pointer;

            .item-img {
                width: 100%;
                height: 164px;
                background: #000;
                border-radius: 4px 4px 0px 0px;
                position: relative;

                img {
                    border-radius: 4px 4px 0px 0px;
                }

                .mark {
                    position: absolute;
                    top: 10px;
                    left: 10px;
                    width: 54px;
                    height: 26px;
                    background: #EA3553;
                    border-radius: 4px;
                    color: #fff;
                    text-align: center;
                }
            }

            .desc-box {
                width: 90%;
                padding: 5%;
                height: 137px;

                .desc-top {
                    width: 100%;
                    font-size: 14px;
                    font-family: PingFang SC;
                    font-weight: 400;

                    .title1 {
                        font-size: 18px;
                        width: 100%;
                    }

                    .title2 {
                        width: 100%;
                        color: #999999;
                        -webkit-line-clamp: 3 !important;
                    }
                }
            }

            .desc-bottom {
                width: 100%;

                .bottom-right1 {
                    color: #00998B;
                }

                .bottom-right2 {
                    color: #875427;
                    position: relative;

                    p.price {
                        color: #E41313;
                        position: absolute;
                        right: 0;
                        top: -25px;
                        padding: 2px 10px;
                    }

                    span {
                        color: #875427;
                        background: #F4DBAB;
                        padding: 2px 10px;
                        font-size: 14px;
                        border-radius: 2px;
                    }
                }
            }
        }

        .video-item:hover {
            box-shadow: 0px 8px 15px 0px rgba(186, 186, 186, 0.3);
        }

        .video-item:nth-child(4n) {
            margin-right: 0;
        }

        .top-right {
            width: auto;
            height: 70px;
            line-height: 70px;
            font-size: 24px;
        }
    }
}


.tuiJian {
    font-size: 22px;
    color: #333333;
    margin-bottom: 10px;
}
</style>
<style scoped>

.bottom-div {
    background-color: #2D2D2D;
    height: calc(100% - 98px);
}

.right-div {
    margin-top: 20px;
    background-color: #2D2D2D;
}

.right-div .introduce-div {
    padding: 0 20px;
    text-indent: 2em;
    text-align: justify;
}

.right-div ::v-deep .el-tab-pane {
    /* padding: 0 20px; */
}

.right-div ::v-deep .el-tabs__header {
    padding: 0 20px;
}

.right-div ::v-deep .el-textarea__inner {
    background: #26262B;
    border: 1px solid #26262B;
    color: #C0C4CC;
}

.right-div ::v-deep textarea::placeholder {
    color: rgb(87, 87, 87) !important;
}

.right-div ::v-deep .el-tabs__nav-wrap::after {
    background-color: #333333;
}

.right-div ::v-deep .el-tabs__item {
    color: #fff;
}

.right-div ::v-deep .el-scrollbar__wrap {
    margin-bottom: 0;
    margin-right: 0;
}

.right-div ::v-deep .el-scrollbar__wrap {
    overflow-x: hidden;
}

.right-div ::v-deep .el-tabs__item.is-active {
    color: #01C2AC;
}

.el-textarea .el-textarea__inner {
    background-color: #1E1E1E;
    border: none;
    color: #fff;
}

.ny-mes .table-responsive {
    width: 100% !important;
    overflow: auto !important;
}

.healthKnow .bar-list .yysc {
    display: none !important;
}

.xi .el-submenu__title {
    padding: 0 !important;
    background: #1fb7a6;
    color: #FFFFFF;
    margin-top: 7px;
}

.zhong .el-submenu__title {
    padding: 0 !important;
    background: #b76c12;
    color: #FFFFFF;
    margin-top: 7px;
}

.xi .subtitle .el-submenu__title:hover {
    background: rgba(31, 183, 166, 0.2);
}

.xi .el-menu-item.is-active,
.xi .el-menu-item:hover {
    color: #01C2AC;
    background: rgba(31, 183, 166, 0.2);
}

.zhong .el-menu-item.is-active,
.zhong .el-menu-item:hover {
    color: #b76c12;
    background: rgba(183, 108, 18, 0.2);
}

.el-image__inner {
    height: 150px;
    width: auto;
}

.healthKnow .el-radio.is-bordered {
    border-radius: 2px;
    width: 156px;
    text-align: center;
}

.healthKnow .el-radio.is-bordered+.el-radio.is-bordered {
    margin-left: 5px;
}

.healthKnow .el-radio {
    margin-right: 0;
}

.healthKnow .el-checkbox-group {
    display: inline;
}

.healthKnow .el-checkbox {
    width: 33.3%;
    margin: 10px 0;
}

/*.el-checkbox__input.is-checked .el-checkbox__inner, .el-checkbox__input.is-indeterminate .el-checkbox__inner {
      background-color: #01c2ac;
      border-color: #01c2ac;
    }
    .el-checkbox__input.is-focus .el-checkbox__inner{
      border-color: #01c2ac;
    }
    .el-checkbox__inner:hover{
      border-color: #01c2ac;
    }
    .el-checkbox__input.is-checked+.el-checkbox__label {
      color: #01c2ac ;
    }*/
.healthKnow .el-checkbox__inner {
    width: 16px;
    height: 16px;
}

.healthKnow .el-checkbox__inner::after {
    height: 9px;
    left: 5px;
    width: 4px;
}

.healthKnow .el-badge__content.is-dot {
    height: 6px;
    width: 6px;
}

.healthKnow .el-badge__content.is-fixed.is-dot {
    right: 0px;
    top: 11px;
}

.healthKnow .el-submenu__title * {
    margin-left: 10px;
}

.el-menu {
    border-right: none;
}

.el-submenu__title i {
    color: #ffffff;
}

.el-menu-item,
.el-submenu__title {
    height: 48px;
    line-height: 48px;
}

.subtitle {
    border: 1px solid #f2f2f2;
}

.subtitle .el-submenu__title i {
    color: #333333;
}

.subtitle .el-submenu__title {
    padding-left: 30px !important;
    background: #ffffff;
    color: #333333;
    margin-top: 0;
    height: 36px;
    line-height: 36px;
}

.subtitle .el-submenu__title:hover {
    color: #1fb7a6;
}

.el-submenu .el-menu-item {
    padding-left: 50px !important;
    height: auto !important;
    line-height: 36px;
    white-space: pre-wrap;
}

.el-menu-item:focus,
.el-menu-item:hover {
    background-color: #ffffff;
}

.el-pagination.is-background .btn-next,
.el-pagination.is-background .btn-prev,
.el-pagination.is-background .el-pager li {
    border-radius: 50%;
}

.el-pagination {
    font-weight: 500;
}

.xi .el-radio.is-bordered.is-checked {
    border-color: #01C2AC;
}

.xi .el-radio__input.is-checked .el-radio__inner {
    border-color: #01C2AC;
    background: #01C2AC;
}

.xi .el-radio__input.is-checked+.el-radio__label {
    color: #01C2AC;
}

.zhong .el-radio.is-bordered.is-checked {
    border-color: #b76c12;
}

.zhong .el-radio__input.is-checked .el-radio__inner {
    border-color: #b76c12;
    background: #b76c12;
}

.zhong .el-radio__input.is-checked+.el-radio__label {
    color: #b76c12;
}

.el-scrollbar__wrap {
    overflow: auto;
}
</style>
